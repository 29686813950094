.assessment {
  margin-bottom: 4px;
}

.assessment label {
  margin: 0;
  display: block;
  font-weight: bold;
}

.assessment__table {
  margin-bottom: 10px;
}

.assessment__form-item {
  margin-bottom: 24px;
}

.assessment__top-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.assessment__save-notice {
  font-style: italic;
}

.assessment__short-name-error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}
