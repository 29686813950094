.editable-label-label {
  height: 100%;
  min-width: 100%;
  cursor: pointer;
}

.editable-label-input.has-error {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}

.editable-label-error {
  color: red;
  font-size: 0.8em;
}

.editable-label__label-disabled {
  color: gray;
  font-style: italic;
}
