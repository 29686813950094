.faculty-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2%;
}

.faculty-header__new {
  margin-right: 0.5em;
}
.faculty-header__sort,
.faculty-header__filter {
  margin-left: 0.5em;
}

.faculty-header__new i {
  position: relative;
  bottom: 2px;
}

.faculty-header__search {
  width: 15rem;
  margin-right: 1em;
}

.faculty-table {
  background: white;
  margin-bottom: 1em;
}

.faculty-table .ant-table-row {
  cursor: pointer;
}
