.loader {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 70rem;
}

.loader-message {
  padding: 1rem 0;
  font-size: 1.25rem;
  text-align: center;
  font-variant: tabular-nums;
  margin: 25% 5%;
}

.loader-spinner {
  margin-right: 1rem;
}
