.main-view {
  position: relative; /* positioning parent for FillMainView HOC */
  min-height: 280px;
  padding: 18px;
  overflow: scroll;
}
/* couldn't scroll in navbar before - this fixes that
   whilst hiding the scroll bars to keep it stylistically the same as it was */
.ant-layout-sider > div > ul:nth-child(1){
  overflow: -moz-scrollbars-none; /* old versions of firefox */
  overflow-y: scroll;
  height: 100%;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.ant-layout-sider > div > ul:nth-child(1)::-webkit-scrollbar{
  display: none;  /* Safari and Chrome */
}
