.attach-media-modal .ant-modal {
  top: 10vh;
  width: 60vw;
  height: 80vh;
  max-width: 980px;
  padding: 0;
}

.attach-media-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.attach-media-modal .ant-modal-header {
  flex: 0 0 auto;
}

.attach-media-modal .ant-modal-body {
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0;
}

.attach-media-modal .ant-modal-body > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.attach-media-modal .ant-modal-body > div > label {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  padding: 20px;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: 0 4px 5px -5px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1001;
}

.attach-media-modal__filter-label {
  margin-right: 5px;
}

.attach-media-modal__media-list {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0 20px;
}

.attach-media-modal .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 auto;
  box-shadow: 0 -4px 5px -5px rgba(0, 0, 0, 0.3);
  position: relative;
}

.attach-media-modal .ant-spin {
  display: block;
  margin-right: 10px;
}

.attach-media-modal .ant-spin-dot {
  display: block;
}
