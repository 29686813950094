.resource-library .section-view .attachment-view .attachment-input-footer {
  justify-content: flex-start;
}

.resource-library .section-view .attachment-view .attachment-header,
.resource-library .section-view .attachment-view .attachment-footer {
  height: 50px;
}
.resource-library .section-view .attachment-view .attachment-input {
  width: 20.4rem;
}

.resource-library .section-view .attachment-view .attachment-input button {
  margin-right: 2rem;
}

.resource-library .section-view .attachment-view .footer {
  width: 100%;
  height: 25px;
}

.resource-library .section-view .attachment-view .drag-handle svg,
.resource-library .section-view .drag-handle svg {
  font-size: 1.25 rem !important;
}

.resource-library .section-view .attachment-view .attachment-input-footer {
  background-color: rgb(255, 255, 255);
  padding-top: 0.25rem;
}

.resource-library
  .section-view
  .attachment-view
  .attachment-input
  .ant-upload-drag-container {
  display: block;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6rem;
  border-radius: 4px;
  cursor: pointer;
}
.resource-library
  .section-view
  .attachment-view
  .attachment-input
  .ant-upload-drag-container
  > p {
  font-family: Helvetica, Arial, sans-serif;
}

.resource-library .section-view .attachment-view .attachment-input label {
  display: flex;
  align-items: baseline;
}

.resource-library .section-view .attachment-view .attachment-input label input {
  margin-left: 0.75rem;
  margin-bottom: 0.75rem;
  width: 100%;
}

.resource-library
  .section-view
  .attachment-view
  .attachment-input
  .with-colon::before {
  content: ':';
  margin-right: 0.25rem;
}

.resource-library
  .section-view
  .attachment-view
  .attachment-input
  .attachments-input__buttons {
  margin-top: 0;
}

.resource-library
  .section-view
  .attachment-view
  .attachment-input
  .ant-upload
  .ant-upload-btn {
  padding-bottom: 0;
}

.resource-library
  .section-view
  .attachment-view
  .attachment-input
  .file-input
  p {
  margin-bottom: 0.25rem;
}

.resource-library
  .section-view
  .attachment-view
  .attachment-input
  .attachments-input__buttons
  button {
  margin-right: 0.75rem;
}
.resource-library .section-view .attachment-view .attachment-input > div {
  width: 100%;
}

.resource-library
  .section-view
  .attachment-view
  .attachment-input
  .ant-upload-drag-icon {
  padding-top: 0.25rem;
}

.resource-library
  .section-view
  .attachment-view
  .attachment-input
  .anticon-download {
  font-size: 1.75rem !important;
}
