.attachment-item__activated-at {
  margin-right: 0.9rem;
}

.attachment-item__activated-at-label, .attachment-item__expired-at-label {
  margin-right: 0.4rem;
}

.attachment-item__dates-container {
  display: flex;
  border-bottom: 0.1rem solid #eee;
  padding-bottom: 0.7rem;
}

.attachment-item__last-updated-on {
  margin-left: 0.7rem;
}
