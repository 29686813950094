.menu-bar {
  display: flex;
  justify-content: flex-start;
}

.menu-bar > * {
  max-width: 200px;
  margin-right: 8px;
}

.table-cell {
  display: flex;
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.child-fully-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chapter-tag-table {
  background: white;
  margin-bottom: 1em;
}

.chapter-tag-table .ant-table-row {
  cursor: pointer;
}

.tag-form-container {
  max-width: 320px;
  margin: 16px 0;
}

.tag-form-container-item {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}

.tag-form-input-box {
  width: 100%;
}

.tag-view__associated-tag .anticon {
  vertical-align: middle;
}
