.topic-group-view .header-row {
  display: flex;
  margin-top: 1.5rem;
}

.topic-group-view .header {
  margin-right: 12px;
}

.topic-group-view .show-hide-topics-button {
  margin-left: 1rem;
}

.topic-group-view .topic-group-header,
.topic-group-view .topic-group-footer {
  background-color: rgba(250, 250, 250, 1);
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  user-select: none;
  padding: 12px;
  box-shadow: none;
}

.topic-group-view .topic-group-header {
  justify-content: space-between;
}

.topic-group-view .topic-group-footer {
  justify-content: flex-start;
}

.topic-group-view .topic-group-header,
.topic-group-view .topic-group-footer {
  height: 50px;
}

.topic-group-view .column-span-small {
  display: flex;
  align-items: center;
  min-width: 40px;
  margin-right: 12px;
  overflow: hidden;
}

.topic-group-view .column-span-medium {
  display: flex;
  align-items: center;
  min-width: 100px;
  margin-right: 12px;
  overflow: hidden;
}

.topic-group-view .column-span-large {
  display: flex;
  align-items: center;
  min-width: 160px;
  margin-right: 12px;
  overflow: hidden;
}

.topic-group-view .editable-label-label {
  min-height: 1.6em;
}

.topic-group-view .footer {
  width: 100%;
  height: 25px;
}

.topic-group-view .button-column {
  display: flex;
  justify-content: flex-end;
}

.topic-group-view .button-column .ant-btn:not(:last-child) {
  margin-right: 4px;
}
