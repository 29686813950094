.bundleItems__column-labels {
  font-family: sans-serif;
  padding-top: 1rem;
}

.bundleItems__column-padded-lg {
  margin: 2rem 0;
}

.bundleItems__column-padded-md {
  margin: 1rem 0;
}

.bundleItems__icon-margin {
  margin-right: 1rem;
}

.bundleItems__select-min-width {
  min-width: 450px;
}

.bundleItems__cart-link {
  padding: 10px;
  font-size: 1rem;
  background-color: #dfe8f2;
}

hr {
  margin-top: 0;
}
