.fill-main {
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  padding-bottom: 10em;
  overflow: auto;
}

.fill-main__close-button {
  height: 2em;
  padding-left: 0;
  padding-bottom: 15px;
}

.fill-main__close-button .anticon {
  vertical-align: 0;
}
