.account label {
  margin: 0;
  font-weight: normal;
}

.account label.account__graduated-at-label {
  margin-right: 0.5rem;
}

.account__property-select {
  width: 220px;
}

.disable__notifications {
  display: 'flex';
  flex-direction: 'row';
  width: '100%';
  height: '60px';
  justify-content: 'flex-end';
  align-items: 'center';
}

.account__page-header {
  display: flex;
  justify-content: flex-end;
}

.account__address-block {
  margin-top: 20px;
}

.account h5 {
  font-weight: bold;
  font-size: 16px;
}

.account__account-details-title {
  width: 200px;
}

.account__free-trial-label {
  color: red;
}

.account__reset-password-container {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.account__reset-password-container > div {
  margin-bottom: 0.5rem;
}
