.cme-manage {
  padding: 20px;
}

.cme-manage__note {
  margin-top: 25px;
  padding: 10px;
  border-radius: 3px;
  background: #fff1b8;
  border: 1px solid #ffe58f;
  font-size: smaller;
}

.cme-manage__disclosures,
.cme-manage__credits {
  margin-top: 25px;
}

.cme-manage__disclosures-table,
.cme-manage__credits-table {
  background-color: #fff;
}
