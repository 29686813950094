.question-option {
  border: 1px solid rgb(204, 204, 204);
  padding: 1rem;
  margin: 1rem 0;
}

.question-option .tox-tinymce {
  height: 10rem !important;
}

.question-option__radio-button {
  display: flex;
  justify-content: flex-end;
}

.question-option__radio-button span {
  font-weight: normal;
}
