.dashboard-lectures-create-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.on-hover-do-nothing:hover {
  background-color: #fff !important;
}

.dashboard-lectures-page-section {
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: tnum;
  position: relative;
  background: #fff;
  border: 1px solid rgb(235, 237, 240);
  padding: 18px;
}

.empty-container {
  width: 250px;
}

.lecture-item {
  max-width: 50%;
  margin-bottom: 8px;
}

.column-span-extra-large {
  display: flex;
  align-items: center;
  width: 260px;
  margin-right: 12px;
  overflow: hidden;
}

.column-span-large {
  display: flex;
  align-items: center;
  width: 160px;
  margin-right: 12px;
  overflow: hidden;
}

.column-span-medium {
  display: flex;
  align-items: center;
  width: 80px;
  margin-right: 12px;
}

.upload-error-styles {
  color: #fff;
  background-color: #f00;
  padding: 1px;
}

.is-dragging-over {
  margin-bottom: 18px;
}

.media-options {
  font-size: 12px;
}

.detach-container {
  margin: 8px 0;
}

.lecture__has-cme-indicator {
  margin: 2rem 0;
}

.lecture-description {
  margin-bottom: 0.5rem;
}

.lecture-description > div {
  cursor: pointer;
  padding: 0.5rem;
  border: 1px dotted #ccc;
  border-radius: 3px;
}

.lecture-description p {
  font-family: inherit !important;
  margin-bottom: 0 !important;
}

.editor-input-editor {
  height: 30rem;
}

.editor-input-editor .public-DraftEditor-content,
.editor-input-raw-editor {
  height: 30rem;
}

.editor-action-buttons {
  display: flex;
  justify-content: space-between;
}

.editor-action-buttons button {
  margin-right: 0.5rem;
}
