.view-promotion__link-button {
  text-decoration: none;
  background-color: #ffffff;
  color: #333333;
  padding: 10px;
  border: 1px solid #cccccc;
}

.generate-promo-link__select-container {
  display: flex;
  flex-direction: column;
}

.generate-promo-link__select {
  margin-bottom: 20px;
}

.generate-promo-link__link {
  padding: 10px 0 10px 0;
  font-size: 16px;
  background-color: #dfe8f2;
  margin-top: 20px;
  text-align: center;
}

.generate-promo-link__breadcrumbs {
  margin-bottom: '20px';
}

.generate-promo-link__container {
  width: 550px;
  margin-top: 20px;
}

.generate-promo-link__back-button {
  margin-top: 20px;
}

.promotion-input {
  width: 400px;
  margin: 1rem;
}
