.editor-input-editor {
  background: #fff;
  border: 1px solid #ccc;
  padding: 0 10px;
  border-radius: 0 0 4px 4px;
  line-height: 1.2em;
  height: 20em;
}

.editor-input-wrapper .rdw-editor-toolbar {
  background: #fcfcfc;
  border: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.editor-input-editor .public-DraftEditor-content,
.editor-input-raw-editor {
  height: 60vh;
  width: 100%;
  overflow: auto;
}

.public-DraftStyleDefault-block {
  margin: 0.5em 0;
}

.editor-input-editor p,
.editor-input-editor h1,
.editor-input-editor h2,
.editor-input-editor h3,
.editor-input-editor h4,
.editor-input-editor h5,
.editor-input-editor h6 {
  line-height: 1.5em;
}

.editor-input-editor {
  font-size: 16px;
}
.editor-input-editor h1 {
  font-size: 2em;
}
.editor-input-editor h2 {
  font-size: 1.5em;
}
.editor-input-editor h3 {
  font-size: 1.17em;
}

.editor-input-wrapper .placeholder-ul {
  visibility: hidden;
}

.editor-input-wrapper .placeholder-li:hover {
  background: #f1f1f1;
}
