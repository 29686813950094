.list-header-menu {
  padding-top: 0;
}

.list-header-menu .ant-popover-arrow {
  display: none;
}

.list-header-menu__option {
  color: black;
  display: block;
  width: 100%;
  text-align: left;
}

.list-header-menu__option:hover {
  background: #eee;
}

.list-header-menu__option--selected {
  color: #40a9ff;
}
