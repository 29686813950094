.tag-select-modal__add,
.tag-select-modal__cancel {
  margin-bottom: 1em;
  margin-top: 1em;
}

.tag-select-modal__button {
  width: 100%;
  margin-top: 0.25rem;
}

.tag-select-modal {
  margin-top: 0.5rem;
}

.tag-select-modal__select {
  width: 60%;
  margin-bottom: 1rem;
}

.tag-select-modal__header {
  margin-bottom: 0.5rem;
}

.tag-select-modal__header-search {
  margin-bottom: 1rem;
}

.tag-select-modal__list-item {
  margin-bottom: 0.25rem;
}

.tag-select-modal__item,
.tag-select-modal__header-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}

.tag-select-modal__header-title {
  font-weight: bold;
}

.tag-select-modal__item--selected {
  background-color: rgb(204, 204, 204);
}
