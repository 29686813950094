.course {
  margin-bottom: 20rem;
}

.course__select {
  width: 100%;
}
.course__tag-system-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.course__tag-system-label-icon {
  margin-left: 4px;
}
.course__title-input {
  width: 280px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
}
.course__sales-page-url-input {
  width: 400px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 4px 11px;
}
.course__title-input:focus {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.course__title-input:hover {
  border-color: #40a9ff;
}

.course .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 30rem;
}

.course__default-item-message {
  margin-top: 1rem;
  color: #900000;
}

.course_disabled-cme-menu-disaclaimer {
  margin-top: 0.5rem;
}
