.questions-menu-bar {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.questions-menu-bar__left--body {
  width: 50%;
}

.questions-menu-bar__right {
  display: flex;
  flex-direction: row;
}

.questions-menu-bar__right--course{
  padding-left: 5px;
}

.questions-menu-bar__right--tag{
  padding-left: 5px;
}

.questions-menu-bar .ant-select {
  width: 15rem;
}

.questions-menu-bar input {
  width: 30rem;
}

.questions-menu-bar__sort {
  margin-left: 0.5em;
}

.questions-list__question-edit-button {
  font-size: 20px;
}

.questions-list__id-column {
  width: 10%;
}

.questions-list__tags-column {
  width: 15%;
}

.questions-list__stem-column {
  width: 55%;
}

.questions-list__course-column {
  width: 10%;
}

.questions-list__select-column {
  width: 15%;
}

.questions-list__text p,
.questions-list__text {
  font-family: sans-serif;
}

.questions-list__text--disabled p,
.questions-list__text--disabled {
  font-style: italic;
  color: #666;
}

.questions-list {
  margin-top: 2rem;
  margin-bottom: 1rem;
  background: white;
}

.question__tag {
  margin-bottom: 6px;
}
