.custom-email-modal__loading-spinner {
  display: flex;
  justify-content: center;
}

.custom-email-modal__subject {
  display: flex;
  align-items: baseline;
}

.custom-email-modal__subject-input,
.custom-email-modal__email-type-select {
  margin-left: 0.5rem;
}

.custom-email-modal__email-type-select {
  width: 200px;
}

.custom-email-modal__preview {
  margin-top: 1rem;
}
