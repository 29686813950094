.attachment-list {
  border-top: 1px solid #eee;
}

.attachment-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 0;
}

.attachment-item-field {
  flex: 1 1 90%;
  display: flex;
  align-items: center;
}

.new-attachment-form {
  margin-top: 1em;
}

.attachment-item-buttons {
  flex: 1 1 200%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.attachment-item-buttons .ant-btn {
  margin: 0 0 0 8px;
  display: flex;
  align-items: center;
}

.attachment-item-field__placeholder {
  color: lightgray;
}

.attachment-upload__filename {
  font-size: 0.75em;
  margin-right: 0.5rem;
}

.attachment-upload__button {
  margin: 0 0 0 8px;
  display: flex;
  align-items: center;
}

.attachment-upload__button .ant-spin-spinning {
  display: block;
  line-height: 0;
}

.attachment-upload__button .ant-spin {
  margin-right: 8px;
}

.new-attachment-form .attachment-upload__filename {
  display: inline-block;
  margin-left: 1em;
}

.new-attachment-form label {
  margin: 0 0 0 8px;
}

.new-attachment-form label:first-child {
  margin: 0;
}

.new-attachment-form__title {
  margin-bottom: 0.75rem;
}

.new-attachment-form__title-input {
  margin-left: 0.5rem;
  width: 15rem;
  border: 1px solid #d9d9d9;
  border-radius: 0.1rem;
  padding: 0.25rem 0.75rem;
}

.new-attachment-form__title-input:focus {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(24, 144, 255, 0.2);
}

.new-attachment-form__title-input:hover {
  border-color: #40a9ff;
}

.attachments-input__buttons {
  display: block;
  margin-top: 16px;
}

.attachments-input__buttons .ant-btn {
  margin: 0 8px 0 0;
}

.attachment-list__header,
.attachment-list__header--rap {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attachment-list__header {
  width: 28%;
}

.attachment-list__header--rap {
  width: 38%;
}

.attachment-list__header--rap .attachment-list__header-title,
.attachment-item-field__title {
  width: 10%;
  max-width: 10%;
  min-width: 10%;
  margin-right: 1rem;
}
.attachment-list__header--rap .attachment-list__header-subtitle,
.attachment-item-field__subtitle {
  width: 10%;
  max-width: 10%;
  min-width: 10%;
  margin-right: 1rem;
}
.attachment-list__header--rap .attachment-list__header-activity-time,
.attachment-item-field__activityTimeInSeconds {
  width: 8%;
  max-width: 8%;
  min-width: 8%;
  margin-right: 1rem;
}

.attachment-list__header--rap .attachment-list__header-content-summary,
.attachment-item-field__isContentSummary {
  width: 10%;
  max-width: 10%;
  min-width: 10%;
  justify-content: center;
}

.attachments-input__rap-inputs {
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.attachments-input__activity-time {
  width: 4rem;
}

div.attachments-input__activity-time {
  display: inline-block;
}

.attachments-input__rap-inputs-container {
  width: 210px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
