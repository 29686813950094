.bundle__column-padded-lg {
  margin: 2rem 0;
}

.bundle__icon-margin {
  margin-right: 1rem;
}

.bundle__select-min-width {
  min-width: 450px;
}

.bundle__table {
  background-color: #fff;
  margin-top: 20px;
}
