.cme-certificate-download {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.cme-certificate-download__start-year,
.cme-certificate-download__end-year,
.cme-certificate-download__courses {
  display: flex;
  flex-direction: column;
}

.cme-certificate-download .ant-select-selector {
  width: 200px;
}

.cme-certificate-download .ant-btn {
  width: 200px;
}
