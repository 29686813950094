.chapter-list-item {
  display: flex;
  width: 100%;
  border: 1px solid lightgray;
  margin: 0.25em 0;
  padding: 0.5em;
  line-height: 1.5rem;
}

.chapter-list-item .ant-row {
  width: 100%;
}

.chapter-list-header {
  background-color: rgba(250, 250, 250, 1);
  margin-bottom: 2px;
  user-select: none;
  padding: 12px;
  box-shadow: none;
  width: 100%;
  font-weight: bold;
  font-size: 12px;
}

.chapter-list-item__tag-expansion-toggle .ant-tag {
  cursor: pointer;
}

.chapter-list-item__tag-expansion-toggle {
  display: inline;
}

.chapter-list-item .anticon {
  vertical-align: middle;
}

.chapter-list-item__delete {
  padding-left: 2em;
}

.chapter-list-item__truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  vertical-align: middle;
}

.chapter-list-item-editing-space {
  display: flex;
}

.chapter-list-item__duration .anticon {
  vertical-align: 1px;
  padding-right: 4px;
}
