.list-editor__input {
  margin: 4px 0 8px;
}

.list-editor__display-item {
  margin-bottom: 2px;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  display: inline-flex;
  justify-content: space-between;
  align-items: baseline;
}

.list-editor_display-item__inner-label {
  text-overflow: ellipsis;
  overflow: hidden;
}

.list-editor__input-tag {
  width: 100%;
  text-align: center;
  background-color: #fff;
  border-style: dashed;
}

.list-editor__input-tag i,
.list-editor__display-item i {
  position: relative;
  bottom: 2px;
}

.ant-tag-hidden {
  display: inline-block;
}
