.cme-certificate-modal.ant-modal {
  top: 20px;
  min-width: 700px;
}

.cme-certificate-modal .date-range {
  display: flex;
}

.cme-certificate-modal .date-range .ant-form-item {
  flex-grow: 1;
}

.cme-certificate-modal .editor-input-editor .public-DraftEditor-content,
.cme-certificate-modal .editor-input-raw-editor {
  height: 50vh;
}
