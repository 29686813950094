.file-input p {
  padding: 1rem 2rem 0;
}

.file-input--inline {
  margin-right: 0.7rem;
}

.file-input--inline .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin: 0;
}

.file-input--inline .ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.file-input--inline .ant-upload.ant-upload-drag .ant-upload {
  padding: 0.25rem 0;
}

.file-input--inline
  .ant-upload.ant-upload-drag
  p.ant-upload-drag-icon
  .anticon {
  font-size: 1rem;
}

.file-input--inline p {
  padding: 0 0.5rem;
}
