.groups-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2%;
}

.groups-header__new {
  margin-right: 0.5em;
}

.groups-header__filter {
  margin-left: 0.5em;
}

.groups-header__new i {
  position: relative;
  bottom: 2px;
}

.groups-header__search {
  width: 15rem;
  margin-right: 1em;
}

.groups-table {
  background: white;
  margin-bottom: 1em;
}

.groups-table .ant-table-row {
  cursor: pointer;
}

.ant-table-thead .ant-table-cell {
  font-size: 0.85rem;
  font-weight: 750;
}

.group__table table {
  width: 100vw;
}

.ant-table-content {
  overflow: auto hidden;
}

.groups-menu-bar {
  display: flex;
  justify-content: end;
  padding-bottom: 2rem;
}
