.association-item__container {
  margin-top: 2rem;
}

.association-item__create-button {
  margin-bottom: 2rem;
}

.association-item__card {
  margin-bottom: 2rem;
  width: 100%;
}

.association-item__delete-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.association-item {
  width: 100%;
}

.association-item__title-container {
  display: flex;
  align-items: center;
}

.association-item__title {
  width: 3rem;
}

.association-item__date-container {
  display: flex;
  flex-direction: column;
}

.association-item__date-optional {
  margin-left: 3rem;
  font-size: 0.75rem;
}

.association-item__discount-title {
  display: flex;
  width: 100%;
}

.association-item__discount-title-container {
  display: flex;
  margin-right: 1rem;
  align-items: center;
  margin-top: 2rem;
}

.association-item__discount-subtext {
  width: 100%;
  font-size: 0.75rem;
  margin-top: 0.625rem;
}

.association_description-column {
  width: 100%;
  margin-top: 2rem;
}

.association-page__title {
  margin-bottom: 1rem;
}

.association-page__association {
  margin-bottom: 1rem;
}
