.assessments-navigation-container {
  padding: 20px 0;
}

.assessments-menu-bar {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.assessments-menu-bar__right {
  display: flex;
  flex-direction: row;
}

.assessments-menu-bar .ant-select {
  width: 15rem;
}

.assessments-menu-bar input {
  width: 30rem;
}

.assessments-menu-bar__sort {
  margin-left: 0.5rem;
}

.assessment-questions__header {
  padding-left: 1rem;
}

.assessment-questions__item {
  padding-top: 1rem;
  padding-left: 1rem;
}

.assessment-questions__item-body {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1rem; /* fallback */
  max-height: 2rem; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin: 0 1rem 0 0;
}

.assessment-questions__item-column {
  display: flex;
  align-items: center;
}

.assessments-list__header {
  margin-bottom: 2%;
}

.assessments-list__table {
  margin-bottom: 1rem;
}

.ant-table-row:hover {
  cursor: pointer;
}
