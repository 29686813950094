.organizations-menu-bar {
  display: flex;
  justify-content: end;
  padding-bottom: 2rem;
}

.organizations-menu-bar__search{
  width: 30%;
  margin-right: 1rem;
}
