.organization--main-view{
  background-color: #FFF;
  padding: 1.5rem;
}

.organization--accordion-menu tr.ant-table-expanded-row>td {
  background-color: #FFF;
}


.organization--accordion-menu  tbody > tr{
  background-color: #FAFAFA;
}
