.topic-view {
  margin-left: 20px;
}

.topic-view .header-row {
  display: flex;
}

.topic-view .header {
  margin-right: 12px;
}

.topic-view .page-section {
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 8;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: tnum;
  position: relative;
  background: #fff;
  border: 1px solid rgb(235, 237, 240);
  padding: 18;
}

.topic-view .add-button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.topic-view .spinner {
  margin-left: 8px;
}

.lecture-view {
  margin-left: 20px;
}

.topic-view .topic-header,
.topic-view .lecture-header,
.topic-view .topic-footer,
.topic-view .lecture-footer {
  background-color: rgba(250, 250, 250, 1);
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  user-select: none;
  padding: 12px;
  box-shadow: none;
}

.topic-view .topic-header,
.topic-view .lecture-header {
  justify-content: space-between;
}

.topic-view .topic-footer,
.topic-view .lecture-footer {
  justify-content: flex-start;
}

.topic-view .topic-header,
.topic-view .topic-footer {
  height: 50px;
}

.topic-view .lecture-header,
.topic-view .lecture-footer {
  height: 30px;
}

.topic-view .lecture-footer button {
  height: 25px;
  font-size: smaller;
}

.topic-view .row {
  justify-content: space-around;
}

.topic-view .footer {
  width: 100%;
  height: 25px;
}
