.media-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.media-item__content {
  display: flex;
  width: 75%;
}

.media-item__controls{
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.media-item__poster-frame {
  height: auto;
  width: 75px;
  margin: auto 20px auto 0;
  min-width: 75px;
}

.media-item__details{
  width: 100%;
}
