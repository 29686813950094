.faculty-member {
  width: 80%;
  margin-bottom: 4px;
}

.faculty-member label {
  margin: 0;
  display: block;
  font-weight: bold;
}

.faculty-member__form-item {
  margin-bottom: 16px;
}

.faculty-member__top-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.faculty-member__save-notice {
  font-style: italic;
}

.faculty-member__view-user-account-button,
.faculty-member__link-to-user-account-button {
  width: 12rem;
}
