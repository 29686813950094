.update-lecture-hierarchy__modal,
.ant-modal-content,
.ant-modal-header {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
}

.ant-modal-title {
  font-size: 1rem;
  font-weight: 700;
}

.update-lecture-hierarchy__title {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 0.9rem;
}

.update-lecture-hierarchy__parent-title {
  margin-bottom: 2rem;
}

.update-lecture-hierarchy__assign {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.update-lecture-hierarchy__detach {
  display: flex;
  flex-direction: column;
}

.update-lecture-hierarchy__select {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 0.75rem 0;
  width: '100%';
}

.update-lecture-hierarchy__remove-button {
  width: auto;
  margin: 1rem 0;
}

.update-lecture-hierarchy__warning {
  font-size: 0.8rem;
  color: #ff4d4f;
}
