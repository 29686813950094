/* Only using !important here to override the existing ant-modal width, which apparently cannot be changed. */
.resource-selector-modal .ant-modal {
  width: 80% !important;
}

.resource-selector-modal__header {
  margin-bottom: 0.25rem;
}

.resource-selector-modal__item--disabled {
  color: lightgray;
}

.resource-selector-modal__item {
  cursor: pointer;
  margin-bottom: 0.25rem;
}

.resource-selector-modal__item .ant-checkbox {
  margin-right: 0.25rem;
}

.resource-selector-modal__header-title-list {
  font-weight: bold;
  text-align: center;
}

.resource-selector-modal__item-render {
  text-align: center;
}

.resource-selector-modal__header-title {
  margin-top: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
