.episode {
  width: 100%;
  margin-bottom: 4px;
}

.episode label {
  font-weight: bold;
}

.episode__description {
  margin-bottom: 2em;
}

.episode__chapter-list__footer .ant-card-body {
  padding: 1rem;
}
.episode__chapter-list__footer button {
  margin-right: 1rem;
}
.episode__chapter-list__footer button .anticon,
.episode__cme button .anticon {
  vertical-align: text-top;
}

.episode__title-display {
  font-size: 24px;
  font-weight: bold;
}

.episode__title {
  display: block;
}

.episode__podcast-select,
.episode__playlist-type,
.episode__title,
.episode__short-name,
.episode__release-date,
.episode__episode-number,
.episode__summary,
.episode__cme {
  display: flex;
  flex-direction: column;
  margin: 2em 0;
}

.episode__chapter-list-container {
  margin-top: 2em;
}

.episode__playlist-type .ant-select,
.episode__podcast-select .ant-select {
  width: 300px;
}

.episode__release-date-picker {
  width: 200px;
}

.episode__cme-manage-button {
  min-width: 140px;
  max-width: 20%;
}

.episode__episode-number__button-group {
  display: flex;
  margin-top: 0.5em;
  width: 250px;
}
.episode__episode-number__button-group > button {
  margin: 0 0.5em 0 0;
  text-align: left;
}
