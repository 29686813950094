.group {
  margin-bottom: 4px;
}

.group__details {
  display: flex;
  flex-direction: column;
}

.group__details__name {
  font-weight: bold;
}

.group__details__manager {
  padding-left: 0.5rem;
}

.group__details__item {
  display: flex;
}

.group label {
  margin: 0;
  font-weight: normal;
}

.group__table {
  margin-bottom: 10px;
}

.group__form-item {
  margin-bottom: 24px;
}

.group__top-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.group__save-notice {
  font-style: italic;
}

.group__short-name-error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.disabled-row {
  color: grey;
}

.group__link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.member-actions {
  display: flex;
}

.member-sub__options .ant-select-selector {
  min-width: 200px;
}

.ant-select.member-sub__options-active-sub:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid green;
  border-radius: 4px;
}

.ant-select.member-sub__options-expired-sub:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid red;
  border-radius: 4px;
}

.ant-collapse-header {
  font-weight: bold;
}
