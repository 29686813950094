.lecture-list--status-icons {
  display: flex;
  flex-direction: column;
}

.lecture-list--media-status {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.lecture-list--attachment-status {
  margin-right: 0.5rem;
}

.lecture-list--media-status--disabled,
.lecture-list--attachment-status--disabled {
  color: #ccc;
}
