.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-table-left-column {
  text-align: right;
  padding-right: 10px;
}

.order-table-paypal {
  font-style: italic;
}

.main-container {
  width: 100%;
  display: flex;
}

.dashboard-page-container {
  flex: 1;
  padding: 20px;
}

.sidebar-container {
  padding-top: 20px;
  padding-right: 20px;
  width: 140px;
}

.alert-container {
  margin-top: 20px;
}

.alert-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-form-item-children button:not(:last-child) {
  margin-right: 10px;
}

.button__link {
  padding: 0;
}

div.note {
  padding: 10px;
  border-radius: 3px;
  background: #fff1b8;
  border: 1px solid #ffe58f;
  font-size: smaller;
}

.section-header {
  display: flex;
  height: 3em;
  align-items: center;
  margin-top:2em;
  margin-bottom: 0.5em;
}

.section-header h5 {
  margin: 0;
}

.section-header button {
  margin-left: 1em;
}

.selector {
  padding: 0 0.5rem 0.5rem 0;
  width: 200px;
}

svg {
  vertical-align: baseline;
}
