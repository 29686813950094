.item__form-container {
  margin-bottom: 1.5rem;
}

.item__sortable-list-container {
  width: 100%;
}

.item__sortable-list-container--suggested {
  width: 100%;
  display: flex;
}

.item__sortable-list-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
}

.item__sortable-list-item--suggested {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0;
  border: 1px solid black;
  margin-bottom: 0.5rem;
}

.item__sortable-list-item__text {
  margin-bottom: 0;
}

.item__select--suggested-item {
  min-width: 80%;
}

.lock-label .anticon {
  padding-left: 0.5em;
}

.item__attention-new-item {
  background-color: #effd5f;
  padding: 4px 0;
  margin: 1rem 0;
  display: flex;
  justify-content: left;
  width: fit-content;
}

.item__is-parent-message {
  margin-bottom: 0.5rem;
}

.item__field-sections {
  background-color: #fff;
  padding: 1.5rem;
}
