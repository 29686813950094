.new-course-modal__course-section {
  display: flex;
  flex-direction: column;
}

.new-course-modal__cme-hours-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.ant-form-item {
  margin-bottom: 0.5rem;
}
