.products__create-button {
  align-self: flex-end;
  justify-self: right;
  margin-bottom: 1rem;
}

.products__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.products__course-list {
  padding: 1rem;
  margin-left: 0.5rem;
}

.course-items__section-title {
  font-weight: 900;
}

.course-items__item-list {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.course-items__item-list li {
  padding-top: 0.25rem;
  margin-left: 0.5rem;
}

.course-items__create-button {
  align-self: flex-end;
  justify-self: right;
}
