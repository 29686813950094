.single-attachment-input {
  max-width: 300px;
  margin-bottom: 1rem;
}

.single-attachment-input__preview-image-button {
  height: 100%;
}

.single-attachment-input__preview-image__logo-image, .single-attachment-input__preview-image__icon-image {
  height: 30px;
}

.single-attachment-input__preview-image-button .anticon {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.7);
  background: rgba(1, 1, 1, 0.2);
}

.single-attachment-input__preview-image-button:hover .anticon {
  display: block;
}

.single-attachment-input__preview-image-button .anticon svg {
  height: 100%;
  width: 100%;
}

.single-attachment-input img {
  max-width: 100%;
}
