.display-note__content {
    border-radius: 10px;
    margin: 0.625rem 0;
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    padding: 0.625rem;
    border: 1px solid #ced4da;
    background-color: #fff;
}

.display-note__top {
    display: flex;
    justify-content: space-between;
}

.display-note__body {
    margin: 1.25rem;
    font-size: 1.125rem;
}


.display-note__text-edit {
    display: flex;
    justify-content: flex-end;
}