.media-input__player {
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  overflow: hidden;
  box-sizing: content-box;
}

.media-input__media-toolbar,
.media-input__poster-frame-toolbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 8px 0 15px;
}

.media-input__media-toolbar > *,
.media-input__poster-frame-toolbar > * {
  margin-right: 8px;
}

.media-input__poster-frame-image-container {
  display: flex;
  align-items: flex-start;
}

.media-input__poster-frame-image {
  position: relative;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  overflow: hidden;
  text-align: center;
}

.media-input__poster-frame-image img {
  display: block;
  min-height: 100px;
  width: 300px;
}

.media-input__poster-frame-image--loading img {
  opacity: 0.5;
}

.media-input__poster-frame-image--loading::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(../images/loading.gif) center no-repeat;
}

.media-input__original-filename {
  margin-bottom: 0.75rem;
}
