
.tag-selector__group{
  margin-bottom :2em;

}

.tag-selector__label{
  display:block;
}

.tag-selector__selector {
  width: 80%;
}
