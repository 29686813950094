.csv-question-upload__button {
  margin-left: 1rem;
}

.csv-question-upload__selectors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.csv-question-upload__courses,
.csv-question-upload__assessments {
  display: flex;
  flex-direction: column;
  width: 45%;
}
