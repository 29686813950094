.add-subscription-modal .ant-modal {
  width: 90% !important;
}

.add-subscription-modal__data {
  display: flex;
  justify-content: space-between;
}

.add-subscription-modal__data-entry {
  display: flex;
  flex-direction: column;
}

.add-subscription-modal__data-entry-course-select {
  width: 25%;
}

.add-subscription-modal__data-entry-select {
  width: 15%;
}

.add-subscription-modal__seats {
  width: 5%;
}

.add-subscription-modal__notes {
  width: 25%;
}
