.question-view {
  margin: 2rem 0;
}

.question-view__question-id {
  margin: 1.5rem 0;
  font-size: 16px;
}

.question-view__main-content {
  display: flex;
  flex-direction: row;
}

.question-view__save-buttons {
  display: flex;
  justify-content: flex-end;
}

.question-view__minor-edit-save {
  margin-left: 1rem;
}

.question-view__right-content {
  margin-left: 1.5rem;
  margin-top: 1.5rem;
  width: 20rem;
}

.question-view__success-alert {
  margin: 1rem;
}

.question-view__live-indicators {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 1rem;
}

.question-view__live-indicators-label {
  font-weight: normal;
}

.question-view__live-indicator {
  color: green;
}

.question-view__not-live-indicator {
  color: red;
}

.question-view__inReview-indicator-label,
.question-view__isMostRecentVersion-indicator-label {
  margin-left: 0.8rem;
}

.question-view__question-images {
  margin-top: 2rem;
}

.question-view__question-images-image-entries {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.question-view__question-images-warning,
.question-view__external-reference-disclaimer {
  font-style: italic;
}

.question-view__question-options {
  margin-top: 2rem;
}

.question-view__external-reference {
  margin: 2rem 0;
}
