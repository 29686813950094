.faculty-account-search__search-form,
.faculty-account-search__current-account-options,
.faculty_account-search__account-display-item {
  display: flex;
  justify-content: space-between;
}

label,
.ant-table-header-column {
  font-weight: bold;
}

.faculty-account-search__open-modal-button {
  width: 100%;
}

.faculty-account-search__account-display-row {
  cursor: pointer;
}

.faculty-account-search__account-display-row-disabled {
  color: #a9a9a9;
  cursor: not-allowed;
}

.faculty-account-search__search-parameters {
  display: flex;
  flex-direction: column;
}

.faculty-account-search__search-parameter-item {
  padding-bottom: 4px;
}

.faculty-account-search__search-form-item {
  margin-right: 2px;
}

.ant-alert {
  margin-bottom: 12px;
}

.faculty-account-search__account-search-loading {
  text-align: center;
}
