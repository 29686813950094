.list-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2%;
}

.list-header__new {
  margin-right: 0.5em;
}
.list-header__sort,
.list-header__filter {
  margin-left: 0.5em;
}

.list-header__new i {
  position: relative;
  bottom: 2px;
}
