.product-link-selector {
  margin-top: 0.5rem;
}

.product-link-selector__select-button {
  width: 100%;
  margin-bottom: 0.5rem;
}

.product-link-selector__select {
  width: 60%;
  margin-bottom: 1rem;
}

.product-link-selector__header {
  margin-bottom: 0.5rem;
}

.product-link-selector__search {
  margin-bottom: 1rem;
}

.product-link-selector__list-item {
  margin-bottom: 0.25rem;
}

.product-link-selector__item,
.product-link-selector__header-title {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}

.product-link-selector__header-title {
  font-weight: bold;
}

.product-link-selector__item--selected {
  background-color: rgb(204, 204, 204);
}
