.resource-library .section-view .header-row {
  display: flex;
  margin-top: 2rem;
  margin-bottom: .5rem;
}

.resource-library .section-view .header {
  margin-right: 12px;
}

.resource-library .section-view .section-header,
.resource-library .section-view .section-footer {
  background-color: rgba(250, 250, 250, 1);
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  user-select: none;
  padding: 12px;
  box-shadow: none;
}

.resource-library .section-view .section-header {
  justify-content: space-between;
  margin-top:0;
}

.resource-library .section-view .section-footer {
  justify-content: flex-start;
}

.resource-library .section-view .section-header,
.resource-library .section-view .section-footer {
  height: 50px;
}

.resource-library .section-view .column-span-small {
  display: flex;
  align-items: center;
  min-width: 2rem;
  margin-right: 12px;
  overflow: hidden;
}

.resource-library .section-view .column-span-medium {
  display: flex;
  align-items: center;
  min-width: 6rem;
  margin-right: 12px;
  overflow: hidden;
}

.resource-library .section-view .column-span-large {
  display: flex;
  align-items: center;
  min-width: 12rem;
  margin-right: 12px;
  overflow: hidden;
}

.resource-library .section-view .attachment-view .replace-file {
  display: block;
  width: 100%;
}

.resource-library .section-view .editable-label-label {
  min-height: 1.6em;
}

.resource-library .section-view .button-column {
  display: flex;
  justify-content: space-between;
}

.resource-library .section-view .button-column .ant-btn:not(:last-child) {
  margin-right: 4px;
}

.resource-library .section-view .attachment-view {
  margin-left: 20px;
  overflow-x: auto;
  min-width: fit-content;
}

.resource-library .section-view .attachment-view .header-row {
  display: flex;
}

.resource-library .section-view .attachment-view .header {
  margin-right: 12px;
}

.resource-library .section-view .attachment-view .page-section {
  box-sizing: border-box;
  margin: 0;
  margin-bottom: 8;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: tnum;
  position: relative;
  background: #fff;
  border: 1px solid rgb(235, 237, 240);
  padding: 18;
}

.resource-library .section-view .attachment-view .add-button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.resource-library .section-view .attachment-view .spinner {
  margin-left: 8px;
}

.resource-library .section-view .attachment-view .attachment-header,
.resource-library .section-view .attachment-view .attachment-footer,
.resource-library .section-view .attachment-view .attachment-input-footer {
  background-color: rgba(250, 250, 250, 1);
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  user-select: none;
  padding: 12px;
  box-shadow: none;
}

.resource-library .section-view .attachment-view .attachment-header {
  justify-content: space-between;
}

.resource-library .section-view .attachment-view .attachment-footer,
.resource-library .section-view .attachment-view .ant-upload-list-item {
  font-size: 0.75em;
}

.resource-library .section-view .attachment-view .ant-upload-list-item-name {
  white-space: normal;
}
