.chapter {
  width: 100%;
}

.chapter label {
  font-weight: bold;
}

.chapter__audio-selection-label {
  font-style: italic;
}

.chapter__show-notes {
  flex-basis: 100%;
  max-width: 120px;
  min-height: 100px;
  border: 1px solid black;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.chapter__audio {
  flex-basis: 100%;
  max-width: 400px;
  min-height: 200px;
  border: 1px solid black;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.chapter__audio-icon {
  font-size: 64px;
  position: relative;
  top: 14px;
}

.chapter__show-notes-icon {
  font-size: 36px;
}

.chapter__audio-description {
  max-width: 300px;
  text-align: center;
}

.chapter__title {
  display: flex;
  flex-direction: column;
  margin: 2em 0;
}

.chapter__faculty-selection,
.chapter__summary,
.chapter__show-notes-selection,
.chapter__tags,
.chapter__free-episode-indicator,
.chapter__has-cme-indicator {
  margin: 2em 0;
}

.chapter__summary-indicator {
  break-after: column;
}

.chapter__show-notes-selection {
  width: 90%;
}

.chapter__show-notes-pdf-selection,
.chapter__audio-selection,
.chapter__tags,
.chapter__episode-select {
  padding: 0.5rem 0;
}

.chapter__title-display {
  font-size: 24px;
  font-weight: bold;
}

.chapter__uses-list {
  width: 80%;
}

.chapter__faculty-selection-display-list {
  width: 80%;
}

.chapter__faculty-selection-display-list-item,
.chapter__faculty-selection-display-list-header {
  border: 1px #d9d9d9 solid;
  margin-bottom: 4px;
  border-radius: 4px;
  padding: 0px 5px;
}

.chapter__faculty-selection-display-list-header {
  font-weight: bold;
  margin-top: 4px;
}
